import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header4'
import Footer from '../layout/footer2'
import {projectContent1,serviceContent2,homeSliderContent4} from '../element/SliderContent'
import ProjectSlider1 from '../element/projectSlider1';
import HomeSlider from '../element/homeslider1'
import ScrollToTop from '../element/scrollToTop';
import TestimonialComp from '../component/testimonial';
import { Helmet } from 'react-helmet';


// import '../../css/skin/skin-2.min.css';
var bg1 = require('./../../images/background/bg-video.png');
const bg = require('../../images/background/cs.jpg')

class Index4 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
      } 
      constructor(props) {
        super(props);
        this.state = {
          showModal: true,
        };}

    render() {
        return (
            
            <>
                <Helmet>
                    <title>Plasma Induction | Advanced Induction Heating And Melting Systems for Industry</title>
                    <meta name="description" content="Discover innovative induction heating and melting systems with Plasma Induction. Our solutions cater to a range of industrial heating and melting needs, offering efficiency and reliability." />
                </Helmet>
        
                <Header/>
                <HomeSlider 
					data={homeSliderContent4}
					contentWrapperCls="sliderStyle4"
					btnCls="site-button"
					btnText="ABOUT US"
					btn2Text="OUR PRODUCTS"
                    linkurl="/aboutus"
                    link2url = "/heatingFurnace"
					hideBtn
                />
                
                <div className="page-content bg-white">
                    <div className="content-block">                        
                    <div className="section-full content-inner bg-white video-section"  style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <div className="container">
                                <div className="section-content">
                                    <div className="row d-flex">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                            <div className="video-bx">
                                                <img src={require('../../images/about/plant top 1.webp')} alt="Signature" />
                                                <div className="video-play-icon">
                                                    <a href="https://www.youtube.com/watch?v=hI54N-PdR4s&t=43s"  target='_blank'className="popup-youtube video bg-primary"><i className="fa fa-play"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                                            <div className="content-bx1">
                                                <h2 className="m-b15 title">About<br /><span className="text-primary"> Plasma Induction </span></h2>
                                                <p className="m-b30">As a one of the leading manufacturer of <b>Induction Heating And Melting Equipments in India</b>, Plasma Induction is a pioneer in offering total induction solutions to its assorted customers across the world.</p>
                                                
                                                <p className="m-b30">we are offering <b>Heating Machine, Melting Machine, Hardening Machine,Annealing Machine And Special Application.</b> </p>
                                            </div>
                                            <div>
                                                <Link to="/aboutus" className="site-button outline outline-2 btnhover11 mx-4">ABOUT US</Link>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full content-inner-2 bg-img-fix overlay-black-dark wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="section-head text-white text-center">
                                    <h3 className="title">Our Products</h3>
                                    <h4>Efficient Furnaces for Precision Heating & Melting</h4>
                                   
                                </div>
                                <div className="row icon-white">
                                    <div className="col-lg-12 col-md-12">
                                        <ProjectSlider1 data={projectContent1}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <TestimonialComp/>
                        
                    </div>
                </div>                
                <Footer/>       
                <ScrollToTop className="style1 white icon-up"/>  




                      
            </>
        )
    }
}
export default Index4;