import React, { Component } from "react";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";
import { sparesContent } from "../element/SliderContent";
import ProjectSlider1 from "../element/projectSlider1";
import ProjectSliderSpares from "../element/projectSliderSpares";
import jsonData from "../JasonData/ScrapCharger.json";

const proimage = require("../../images/product/spares/ScrapChr.png");
const proimage2 = require("../../images/product/spares/ScrapChr2.png");
const proimage1 = require("../../images/product/spares/13 (2).png");
// const proimage2 = require("../../images/product/spares/13 (2).png");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.SPARESPDFURL; // Replace with the actual path to your PDF file
  link.download = "SparesCatalouge.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class ScrapchargerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        {/* start */}
        <div className="page-content " style={{ background: "#f4f4f4" }}>
          <div className="content-block">
            <div className="section-full content-inner video-section">
              <div className="container">
                <div className="section-content">
                  <div className="row d-flex">
                    <div className="col-lg-6 col-md-12 m-b30">
                      <label className="gradiant-label">Scrap Charger</label>
                      <div className="card-body card-body-style">
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            The following are the key features of Portable
                            Scalping Vibrating Conveyor.
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            Patented charge feeder nosepiece expands to prevent
                            jams, fits tight to furnace hood to contain fumes
                            and any potential splash back, and directs charge
                            into center of furnace to reduce furnace damage.
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            Noise reduction vibrating feeder troughs for quiet
                            loading and unloading.
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            Heavy duty vibratory feeder designed for 24/7, 365
                            operation with little maintenance requirements
                            reduces your planned downtime.
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            Two-mass, natural frequency charge feeder design
                            requires very little energy to operate, reducing
                            your energy costs.{" "}
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            Variable speed material discharge.
                          </ui>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                      <div className="video-bx">
                        <img
                          className="img-fluid"
                          src={proimage}
                          alt="image"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end */}
        {/* Detals */}
        <div className="page-content " style={{ background: "#f4f4f4" }}>
          <div className="content-block">
            <div className="section-full content-inner video-section">
              <div className="container">
                <div className="section-content">
                  <div className="row d-flex">
                    <div className="col-lg-12 col-md-12 m-b30 align-self-center video-infobx">
                      <table>
                        <thead>
                          <tr>
                            <th>TYPE</th>
                            <th>DETAILS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jsonData.map((data) => (
                            <tr key={data.id}>
                              <td>{data.dia}</td>

                              <td>{data.production}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end */}
        {/* Advantage */}
        <div className="page-content " style={{ background: "#f4f4f4" }}>
          <div className="content-block">
            <div className="section-full content-inner video-section">
              <div className="container">
                <div className="section-content">
                  <div className="row d-flex">
                    <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                      <div className="video-bx">
                        <img
                          className="img-fluid"
                          src={proimage2}
                          alt="image"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 m-b30">
                      <label className="gradiant-label">Best Advantages </label>
                      <div className="card-body card-body-style">
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            very fast as compared to manual charging pf furnace
                            thereby enabling optimum use of the meliting system.
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            Extremely controlled charging of material into the
                            furnace
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            Prevents damage to the furnace lining since the
                            material is properly uided into the furnace so asto
                            avoid the material from hitting the fuenace walls.
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                            No direct exposure of manpower to the furnace
                            thereby preventing dangerous accidents due to metal
                            splash(since the operator can carry out the
                            operating at a very safe distance.)
                          </ui>
                        </ul>
                        <ul
                          className="my-2 col-sm mx-3"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          <i
                            className="fa fa-gear row"
                            style={{ fontSize: "16px", fontWeight: "550" }}
                          ></i>
                          <ui className="mx-4">
                           Minimum heat loss of the furnace since the charging process can be maintained continuously at the required rate
                          </ui>
                        </ul>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end */}
      </>
    );
  }
}
export default ScrapchargerDetails;
